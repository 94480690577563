<template>
  <div class="text-green-swamp min-h-[842px]">
    <h2 class="section-header">
      {{ t('Направлення компанії ІБІС') }}
    </h2>
    <div class="section-content">
      <div
        class="section-content--element"
        :class="[isFishing && 'order-last']"
      >
        <nuxt-img
          src="/images/about/hunter.png"
          class="min-h-[210px] md:min-h-[320px] object-contain"
          height="440"
          width="700"
          format="webp"
          loading="lazy"
        />
        <div class="grid gap-4" v-html="props.blockData?.weapon?.content"></div>
      </div>
      <div class="section-content--element">
        <nuxt-img
          src="/images/about/fisher.png"
          class="min-h-[210px] md:min-h-[320px] object-contain"
          height="440"
          width="700"
          format="webp"
          loading="lazy"
        />
        <div
          class="grid gap-4"
          v-html="props.blockData?.fishing?.content"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { isFishing } = useStoreType()
const { t } = useI18n()

const props = defineProps({
  blockData: {
    type: Object,
    default: () => {}
  }
})
</script>

<style lang="postcss" scoped>
.section-header {
  @apply text-lg mb-6 uppercase font-bold md:mb-16 md:text-3xl md:leading-8;
}
.section-content {
  @apply flex flex-col sm:flex-row gap-10 md:gap-7;
}
.section-content--element {
  @apply flex flex-1 flex-col gap-3 md:gap-5;
}
</style>
